import { configToQs } from '~/helpers/configToQs'

import type { CoreFetch } from '~/types/CoreFetch'

export function provideNotificationPeek(coreFetch: CoreFetch) {
  return <T extends 'band' | 'influencer'>(
    bandId?: T extends 'band' ? number : undefined,
  ): Promise<{ id: number; last_peek_notifications: string }> => {
    return coreFetch.$post(
      `/notification/notification/peek/?${configToQs({
        band_id: bandId,
      })}`,
    )
  }
}

<template>
  <div
    v-if="displaySelf"
    v-click-outside="closeNotificationsMenu"
    class="notificationMenuItem tw-flex tw-items-center"
  >
    <div class="tw-relative tw-cursor-pointer" @click="toggleNotificationsMenu">
      <BtnIcon
        data-test-id="bandTopNavigationNotificationsCTA"
        :aria-controls="displayNotificationsMenu ? uniqueId : undefined"
        :aria-expanded="displayNotificationsMenu"
        :aria-label="$t('notifications.toggleMenu')"
      >
        <!-- TODO: add unread notifications number to aria-label so it can also read something like "5 unread notifications" -->
        <i
          class="fa-bell"
          aria-hidden
          :class="[
            displayNotificationsMenu ? 'fas' : 'far',
            { displayNotificationsMenu },
          ]"
        />
      </BtnIcon>
      <transition name="fade" mode="out-in">
        <div
          v-if="unpeekedItems"
          class="tw-absolute tw-bottom-5 tw-left-3 tw-overflow-hidden tw-rounded-full tw-border-2 tw-border-white tw-bg-orange-500"
          aria-hidden
        >
          <VText
            cfg="sans/13/medium"
            color="white"
            class="tw-flex tw-h-4 tw-min-w-[16px] tw-items-center tw-justify-center tw-rounded-full tw-leading-3"
            :class="{ '!tw-min-w-[24px]': unpeekedItems > 9 }"
          >
            {{ unpeekedItemsDisplayValue }}
          </VText>
        </div>
      </transition>
    </div>
    <NotificationsMenu
      v-if="displayNotificationsMenu"
      :id="uniqueId"
      :mobile="SCREEN_WIDTH < Breakpoints.sm"
      class="notificationsMenu"
      @close="closeNotificationsMenu"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import { vClickOutside } from '~/directives/ClickOutside'

import NotificationsMenu from '~/components/notifications-v2/NotificationsMenu.vue'
import BtnIcon from '~/components/ui/Buttons/icon.vue'
import VText from '~/components/ui/VText.vue'

import eventsMixin from '~/mixins/events'

import { useNotificationSocket } from '~/composables/useNotificationSocket'
import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'

import { provideNotificationGetUnpeekedCount } from '~/api-core/Notifications/GetUnpeekedCount'
import { provideNotificationPeek } from '~/api-core/Notifications/MarkAsPeeked'

import { Breakpoints } from '~/enums/breakpoints'

const component = defineComponent({
  components: {
    NotificationsMenu,
    BtnIcon,
    VText,
  },
  directives: {
    clickOutside: vClickOutside,
  },
  mixins: [eventsMixin],
  emits: ['menu-open'],
  setup() {
    const uniqueId = useId()
    const { addSocketEvent, removeSocketEvent } = useNotificationSocket()
    const { coreFetch } = useProvideCoreFetch()

    return {
      uniqueId,
      removeSocketEvent,
      addSocketEvent,
      coreFetch,
    }
  },
  data() {
    return {
      Breakpoints,
      unpeekedItems: 0,
      socketListenerId: null as null | string,
      displayNotificationsMenu: false,
    }
  },
  computed: {
    ...mapState(useMiscResizeStore, ['SCREEN_WIDTH']),
    ...mapState(useUserBandStore, {
      USER_BAND_ID: 'id',
      USER_HAS_BAND_SELECTED: 'HAS_BAND_SELECTED',
    }),
    ...mapState(useUserStore, {
      USER_IS_INF: 'IS_INF',
      USER_IS_BAND: 'IS_BAND',
    }),
    unpeekedItemsDisplayValue(): string {
      return this.unpeekedItems < 10 ? this.unpeekedItems.toString() : '9+'
    },
    displaySelf(): boolean {
      return (
        this.USER_IS_INF || (this.USER_IS_BAND && this.USER_HAS_BAND_SELECTED)
      )
    },
  },
  watch: {
    displaySelf(): void {
      this.refreshUnpeekedCount()
    },
  },
  async mounted() {
    this.socketListenerId = this.addSocketEvent(() => {
      if (this.displayNotificationsMenu) return

      this.unpeekedItems += 1
    })
    await this.refreshUnpeekedCount()
  },
  beforeUnmount() {
    if (!this.socketListenerId) return
    this.removeSocketEvent(this.socketListenerId)
  },
  methods: {
    /**
     * Used to close the context menu from the notification bell.
     *
     * @description Yes, this is ment to be used with a $ref from a parent.
     */
    closeNotificationsMenu(): void {
      this.displayNotificationsMenu = false
    },
    async refreshUnpeekedCount() {
      if (!this.displaySelf) return

      const getUnpeekedCount = provideNotificationGetUnpeekedCount(
        this.coreFetch,
      )
      const { count } = await (this.USER_IS_BAND
        ? getUnpeekedCount<'band'>(this.USER_BAND_ID)
        : getUnpeekedCount<'influencer'>())

      this.unpeekedItems = count
    },
    toggleNotificationsMenu(): void {
      const wantToOpen = !this.displayNotificationsMenu

      if (wantToOpen) {
        const peekNotifications = provideNotificationPeek(this.coreFetch)

        ;(this.USER_IS_BAND
          ? peekNotifications<'band'>(this.USER_BAND_ID)
          : peekNotifications<'influencer'>()
        ).then(() => {
          this.unpeekedItems = 0
        })
        this.$emit('menu-open')
      }
      this.displayNotificationsMenu = !this.displayNotificationsMenu

      if (this.displayNotificationsMenu) {
        this.trackEvent({
          category: 'Notifications V2',
          action: 'Bell clicked',
          // TODO: add value prop for number of unread notifications
        })
      }
    },
  },
})

export default component
export type BellIconComponentType = Pick<
  InstanceType<typeof component>,
  'closeNotificationsMenu'
>
</script>

<style lang="scss" scoped>
.notificationMenuItem i.fa-bell {
  @apply tw-text-xl;
}

/**
 * 0 - 1005 - 015
 * 0 - 0100 - ~15
 */
@keyframes bell-wiggle {
  0% {
    transform: rotate(0deg);
  }
  15% {
    transform: rotate(45deg);
  }
  30% {
    transform: rotate(-30deg);
  }
  44% {
    transform: rotate(30deg);
  }
  64% {
    transform: rotate(-15deg);
  }
  84% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .displayNotificationsMenu {
    animation: bell-wiggle 1005ms 1;
    transform-origin: top;
  }
}
</style>

import { acceptHMRUpdate, defineStore } from 'pinia'

import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { buildNotificationFromRawCoreData } from '~/helpers/notification-v2/buildFromRaw'

import { provideFetchNotifications } from '~/api-core/Notifications/FetchNotifications'
import { provideReadNotifications } from '~/api-core/Notifications/ReadNotifications'

import type { Notification } from '~/types/notification-v2'

const NO_NOTIFICATIONS_FETCHED_ON_MORE = 10
const MAX_NOTIFICATIONS_LOADED = 100

export type NotificationState = Pick<
  ReturnType<typeof useNotificationV2Store>,
  'all' | 'moreResultsAvailable' | 'loading'
>

export const useNotificationV2Store = defineStore('notificationV2', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)
  const userBandStore = useUserBandStore($pinia)

  const all = ref<Notification[]>([])
  const moreResultsAvailable = ref(false)
  const loading = ref(false)

  function SET_ALL(notifications: Notification[]) {
    all.value.splice(0, all.value.length, ...notifications)
  }

  function PUSH_ALL(notifications: Notification[]) {
    all.value.push(...notifications)
  }

  function UPDATE_BY_ID(updatedNotification: Notification): void {
    const index = all.value.findIndex((i) => i.id === updatedNotification.id)
    if (index === -1) all.value.unshift(updatedNotification)
    else all.value.splice(index, 1, updatedNotification)
  }

  function SET_MORE_AVAILABLE(hasMore: boolean) {
    moreResultsAvailable.value = hasMore
  }

  function SET_LOADING(isLoading: boolean) {
    loading.value = isLoading
  }

  async function FETCH_INITIAL() {
    if (userStore.KIND === null)
      throw new Error('Cannot fetch notifications for unknown user type')

    SET_LOADING(true)
    const fetchNotifications = provideFetchNotifications(coreFetch)

    try {
      const response =
        userStore.KIND === 'band'
          ? await fetchNotifications<'band'>(userBandStore.id.toString())
          : await fetchNotifications<'influencer'>()
      const notifs: Notification[] = response.results.map(
        buildNotificationFromRawCoreData,
      )

      SET_ALL(notifs)
      const hasMore =
        !!response.next && notifs.length < MAX_NOTIFICATIONS_LOADED
      SET_MORE_AVAILABLE(hasMore)
    } finally {
      SET_LOADING(false)
    }
  }

  async function FETCH_MORE() {
    if (userStore.KIND === null)
      throw new Error('Cannot fetch notifications for unknown user type')

    if (!moreResultsAvailable.value || loading.value) return

    SET_LOADING(true)

    const currentNotificationsNo = all.value.length
    const limit = Math.min(
      MAX_NOTIFICATIONS_LOADED - currentNotificationsNo,
      NO_NOTIFICATIONS_FETCHED_ON_MORE,
    )

    const fetchNotifications = provideFetchNotifications(coreFetch)

    try {
      const response =
        userStore.KIND === 'band'
          ? await fetchNotifications<'band'>(
              userBandStore.id.toString(),
              currentNotificationsNo,
              limit,
            )
          : await fetchNotifications<'influencer'>(
              undefined,
              currentNotificationsNo,
              limit,
            )

      const notifs: Notification[] = response.results.map(
        buildNotificationFromRawCoreData,
      )

      PUSH_ALL(notifs)
      const newNotificationsNo = all.value.length
      const hasMore =
        !!response.next && newNotificationsNo < MAX_NOTIFICATIONS_LOADED
      SET_MORE_AVAILABLE(hasMore)
    } finally {
      SET_LOADING(false)
    }
  }

  async function MARK_READ({ id }: { id: number }) {
    if (userStore.KIND === null)
      throw new Error('Cannot set notification to read for unkown user type')

    const notification = all.value.find((n) => n.id === id)
    if (!notification) throw new Error('Notification not found')

    const postData = {
      ids: [id],
    }
    const readNotification = provideReadNotifications(coreFetch)

    try {
      userStore.KIND === 'band'
        ? await readNotification<'band'>(postData, userBandStore.id.toString())
        : await readNotification<'influencer'>(postData)

      const updatedNotification: Notification = {
        ...notification,
        dateSeen: new Date(),
      }
      UPDATE_BY_ID(updatedNotification)
    } catch (_) {
      // mute error
    }
  }

  async function MARK_ALL_AS_READ() {
    if (userStore.KIND === null) {
      throw new Error(
        'Cannot mark all notifications as read for unkown user type',
      )
    }

    const postData = {
      see_all: true,
    }
    const readNotification = provideReadNotifications(coreFetch)

    try {
      userStore.KIND === 'band'
        ? await readNotification<'band'>(postData, userBandStore.id.toString())
        : await readNotification<'influencer'>(postData)

      const now = new Date()
      const updatedNotifications = all.value.map((n) => ({
        ...n,
        dateSeen: now,
      }))
      SET_ALL(updatedNotifications)
    } catch (_) {
      // mute error
    }
  }

  return {
    // state
    all,
    moreResultsAvailable,
    loading,

    // actions
    SET_ALL,
    PUSH_ALL,
    UPDATE_BY_ID,
    SET_MORE_AVAILABLE,
    SET_LOADING,
    FETCH_INITIAL,
    FETCH_MORE,
    MARK_READ,
    MARK_ALL_AS_READ,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationV2Store, import.meta.hot),
  )
}

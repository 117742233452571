import type { CoreFetch } from '~/types/CoreFetch'
import type {
  BaseNotification,
  KindToAdditionalContextMap,
  NotificationKind,
} from '~/types/notification-v2'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export type ApiNotification<T extends NotificationKind = NotificationKind> =
  BaseNotification<T, KindToAdditionalContextMap[T]>

type ApiFetchNotificationsResponse = PaginatedApiResponse<ApiNotification>

export function provideFetchNotifications(coreFetch: CoreFetch) {
  return <T extends 'influencer' | 'band'>(
    bandId?: T extends 'band' ? string : undefined,
    offset = 0,
    limit = 20,
  ): Promise<ApiFetchNotificationsResponse> => {
    return coreFetch.$get<ApiFetchNotificationsResponse>(
      `/notification/notification/`,
      {
        params: {
          band_id: bandId,
          limit,
          offset,
        },
      },
    )
  }
}
